<template>
    <Layout>
        <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            filename="amendment"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <div class="pdf">
                    <div class="section_top">
                        <p>{{ item.noc_ref_no }}</p>
                        <p>
                            {{ item.management_approval_date }} :<bdi
                                >التاريخ</bdi
                            >
                        </p>
                        <p>
                            : السادة / دائرة الأراضى والأملاك &nbsp; المحترمين
                        </p>
                        <p>
                            <bdi> دبى ــ الإمارات العربية المتحدة</bdi>
                        </p>
                        <p>
                            <bdi> ص.ب : 1166 </bdi>
                        </p>
                        <p><bdi> تحية طيبة وبعد ،،، </bdi></p>
                    </div>
                    <br />
                    <div class="subject_text">
                        <h4>
                            ({{ item.unit_no }})
                            <bdi> الموضوع: لا مانع من الهبة </bdi>
                        </h4>
                        <p>
                            يرجى التفضل بالعلم بأنه لا مانع لدينا وقد وافق
                            الواهب بموجب توقيعه المذيل أدناه على إصدار شهادة
                            الملكية باسم الموهوب له و قيام دائرتكم الموقرة
                            بتسجيل العقــــار باسم الموهوب له المذكــــور أدناه
                            والواقع في منطقة دبي لاند بمشروع فالكن ستي اوف وندرز
                            ، وذلك حسب الإجراءات المتبعة لديكم ووفقاً للبيانات
                            التالية :-
                        </p>
                    </div>

                    <div class="name">
                        <p><bdi> الواهب :</bdi></p>
                    </div>
                    <table>
                        <tr>
                            <td>
                                <p>{{ item.name_arabic }}</p>
                            </td>
                            <td class="arabic">
                                <p>الاسم باللغة الانجليزية</p>
                            </td>
                        </tr>
                        <tr>
                            <td v-if="item.owners.length > 0" class="arabic">
                                {{ item.user_name }}
                                <p
                                    v-for="owner in item.owners"
                                    :key="owner.id"
                                    class="arabic"
                                >
                                    {{ owner.name }}
                                </p>
                            </td>

                            <td v-if="item.owners.length == 0" class="arabic">
                                {{ item.user_name }}
                            </td>
                            <td class="arabic">الاسم باللغة الانجليزية</td>
                        </tr>
                        <tr>
                            <td class="arabic">
                                {{ item.nationality }}
                            </td>
                            <td class="arabic">الجنـسيــــــــــــــــــة</td>
                        </tr>
                        <tr>
                            <td class="arabic">
                                {{ item.passport_no }}
                            </td>
                            <td class="arabic">رقم الجواز</td>
                        </tr>
                        <tr>
                            <td class="arabic">100%</td>
                            <td class="arabic">نسبــة الملكيـــــــة</td>
                        </tr>
                        <tr>
                            <td class="arabic">سكني</td>
                            <td class="arabic">طبيعـــة العقــــار</td>
                        </tr>
                        <tr>
                            <td class="arabic">فالكن ستي اوف وندرز</td>
                            <td class="arabic">المشــــــــروع</td>
                        </tr>
                        <tr>
                            <td class="arabic">Residential</td>
                            <td class="arabic">طبيعـــة العقــــار</td>
                        </tr>
                        <tr>
                            <td class="arabic">Falconcity of Wonders</td>
                            <td class="arabic">المشــــــــروع</td>
                        </tr>
                        <tr>
                            <td class="arabic">
                                {{ item.unit_type }}
                            </td>
                            <td class="arabic">نوع الوحدة</td>
                        </tr>
                        <tr>
                            <td class="arabic">
                                {{ item.unit_no }}
                            </td>
                            <td class="arabic">رقــم الوحدة</td>
                        </tr>
                        <tr>
                            <td class="arabic">لا يوجد</td>
                            <td class="arabic">جــهــة الرهــــن</td>
                        </tr>
                        <tr>
                            <td class="arabic">AED {{ item.unit_price }}</td>
                            <td class="arabic">( قيمـــة البــيـــع (درهم</td>
                        </tr>
                        <tr>
                            <td class="arabic">Not Applicable</td>
                            <td class="arabic">جــهــة الرهــــن</td>
                        </tr>
                        <tr>
                            <td class="arabic">Wadi Al Safa</td>
                            <td class="arabic">اسم المنطقة</td>
                        </tr>
                        <tr>
                            <td class="arabic">
                                {{ item.unit_plot_no }}
                            </td>
                            <td class="arabic">رقم الأرض</td>
                        </tr>
                    </table>

                    <br />

                    <p class="name">الموهوب :</p>
                    <table>
                        <tr>
                            <td></td>
                            <td class="arabic">الاسم باللغة العربية</td>
                        </tr>
                        <tr>
                            <td v-if="item.buyers.length > 0" class="arabic">
                                <p
                                    v-for="buyer in item.buyers"
                                    :key="buyer.id"
                                    class="arabic"
                                >
                                    {{ buyer.buyer_name }}
                                </p>
                            </td>
                            <td v-if="item.buyers.length == 0" class="arabic">
                                {{ item.buyer_name }}
                            </td>
                            <td class="arabic">الاسم باللغة الانجليزية</td>
                        </tr>
                        <tr>
                            <td v-if="item.buyers.length > 0" class="arabic">
                                <p
                                    v-for="buyer in item.buyers"
                                    :key="buyer.id"
                                    class="arabic"
                                >
                                    {{ buyer.buyer_country_name }}
                                </p>
                            </td>
                            <td class="arabic" v-if="item.buyers.length == 0">
                                {{ item.buyers[0].buyer_country_name }}
                            </td>
                            <td class="arabic">الجنسيــــــــــــــــة</td>
                        </tr>
                        <tr>
                            <td v-if="item.buyers.length > 0" class="arabic">
                                <p
                                    v-for="buyer in item.buyers"
                                    :key="buyer.id"
                                    class="arabic"
                                >
                                    {{ buyer.buyer_passport }}
                                </p>
                            </td>
                            <td class="arabic" v-if="item.buyers.length == 0">
                                {{ item.buyers[0].buyer_passport }}
                            </td>
                            <td class="arabic">رقم الجواز</td>
                        </tr>
                        <tr>
                            <td class="arabic">100%</td>
                            <td class="arabic">نسبــة الملكيـــــــة</td>
                        </tr>
                        <tr>
                            <td class="arabic">سكني</td>
                            <td class="arabic">طبيعـــة العقــــار</td>
                        </tr>
                        <tr>
                            <td class="arabic">فالكن ستي اوف وندرز</td>
                            <td class="arabic">المشــــــــروع</td>
                        </tr>
                        <tr>
                            <td class="arabic">Residential</td>
                            <td class="arabic">طبيعـــة العقــــار</td>
                        </tr>
                        <tr>
                            <td class="arabic">Falconcity of Wonders</td>
                            <td class="arabic">المشــــــــروع</td>
                        </tr>
                        <tr>
                            <td class="arabic">
                                {{ item.unit_type }}
                            </td>
                            <td class="arabic">نوع الوحدة</td>
                        </tr>
                        <tr>
                            <td class="arabic">
                                {{ item.unit_no }}
                            </td>
                            <td class="arabic">رقــم الوحدة</td>
                        </tr>
                        <tr>
                            <td class="arabic">لا يوجد</td>
                            <td class="arabic">جــهــة الرهــــن</td>
                        </tr>
                        <tr>
                            <td class="arabic">وادي الصفا 2</td>
                            <td class="arabic">اسم المنطقة</td>
                        </tr>
                        <tr>
                            <td class="arabic">AED {{ item.unit_price }}</td>
                            <td class="arabic">( قيمـــة البــيـــع (درهم</td>
                        </tr>
                        <tr>
                            <td class="arabic">Not Applicable</td>
                            <td class="arabic">جــهــة الرهــــن</td>
                        </tr>
                        <tr>
                            <td class="arabic">Wadi Al Safa</td>
                            <td class="arabic">اسم المنطقة</td>
                        </tr>
                        <tr>
                            <td class="arabic">
                                {{ item.unit_plot_no }}
                            </td>
                            <td class="arabic">رقم الأرض</td>
                        </tr>
                    </table>
                    <br />
                    <div class="noc_footer">
                        <p>
                            لذلك نرجو إصدار سند ملكية العقار المذكور وفقاً لعقد
                            البيع و الشراء باسم المشتري أعلاه محملاً بالشرط
                            التالي :
                        </p>
                        <p>
                            يخضع هذا السند لشروط وأحكام نظام المجمع الرئيسي
                            والنظام الأساسي لجمعية الملاك فى مشروع فالكن ستي اوف
                            وندرز.
                        </p>

                        <p>،،، ولكــــم جزيل الشكــــــر والتقـــــــدير</p>
                    </div>

                    <div>
                        <p>
                            <strong> عن / فالكن ستي اوف وندرز ذ.م.م </strong>
                        </p>
                    </div>

                    <div class="noc_footer">
                        <p style="text-decoration: underline">ملحوظة :</p>

                        <p>وافق الواهب على تسليم هذه الشهادة إلى الموهوب له</p>
                        <p>دون تحمل شركة فالكن ستي أدنى مسؤولية اتجاهه</p>
                        <p>توقيع الواهب :</p>
                    </div>
                </div>
            </section>
        </VueHtml2pdf>
        <div class="section_top">
            <p>{{ item.noc_ref_no }}</p>
            <p>{{ item.management_approval_date }} :<bdi>التاريخ</bdi></p>
            <p>: السادة / دائرة الأراضى والأملاك &nbsp; المحترمين</p>
            <p>
                <bdi> دبى ــ الإمارات العربية المتحدة</bdi>
            </p>
            <p>
                <bdi> ص.ب : 1166 </bdi>
            </p>
            <p><bdi> تحية طيبة وبعد ،،، </bdi></p>
        </div>
        <br />
        <div class="subject_text">
            <h4>
                ({{ item.unit_no }})
                <bdi> الموضوع: لا مانع من الهبة </bdi>
            </h4>
            <p>
                يرجى التفضل بالعلم بأنه لا مانع لدينا وقد وافق الواهب بموجب
                توقيعه المذيل أدناه على إصدار شهادة الملكية باسم الموهوب له و
                قيام دائرتكم الموقرة بتسجيل العقــــار باسم الموهوب له
                المذكــــور أدناه والواقع في منطقة دبي لاند بمشروع فالكن ستي اوف
                وندرز ، وذلك حسب الإجراءات المتبعة لديكم ووفقاً للبيانات التالية
                :-
            </p>
        </div>

        <div class="name">
            <p><bdi> الواهب :</bdi></p>
        </div>
        <table>
            <tr>
                <td>
                    <p>{{ item.name_arabic }}</p>
                </td>
                <td class="arabic">
                    <p>الاسم باللغة الانجليزية</p>
                </td>
            </tr>
            <tr>
                <td v-if="item.owners.length > 0" class="arabic">
                    {{ item.user_name }}
                    <p
                        v-for="owner in item.owners"
                        :key="owner.id"
                        class="arabic"
                    >
                        {{ owner.name }}
                    </p>
                </td>

                <td v-if="item.owners.length == 0" class="arabic">
                    {{ item.user_name }}
                </td>
                <td class="arabic">الاسم باللغة الانجليزية</td>
            </tr>
            <tr>
                <td class="arabic">
                    {{ item.nationality }}
                </td>
                <td class="arabic">الجنـسيــــــــــــــــــة</td>
            </tr>
            <tr>
                <td class="arabic">
                    {{ item.passport_no }}
                </td>
                <td class="arabic">رقم الجواز</td>
            </tr>
            <tr>
                <td class="arabic">100%</td>
                <td class="arabic">نسبــة الملكيـــــــة</td>
            </tr>
            <tr>
                <td class="arabic">سكني</td>
                <td class="arabic">طبيعـــة العقــــار</td>
            </tr>
            <tr>
                <td class="arabic">فالكن ستي اوف وندرز</td>
                <td class="arabic">المشــــــــروع</td>
            </tr>
            <tr>
                <td class="arabic">Residential</td>
                <td class="arabic">طبيعـــة العقــــار</td>
            </tr>
            <tr>
                <td class="arabic">Falconcity of Wonders</td>
                <td class="arabic">المشــــــــروع</td>
            </tr>
            <tr>
                <td class="arabic">
                    {{ item.unit_type }}
                </td>
                <td class="arabic">نوع الوحدة</td>
            </tr>
            <tr>
                <td class="arabic">
                    {{ item.unit_no }}
                </td>
                <td class="arabic">رقــم الوحدة</td>
            </tr>
            <tr>
                <td class="arabic">لا يوجد</td>
                <td class="arabic">جــهــة الرهــــن</td>
            </tr>
            <tr>
                <td class="arabic">AED {{ item.unit_price }}</td>
                <td class="arabic">( قيمـــة البــيـــع (درهم</td>
            </tr>
            <tr>
                <td class="arabic">Not Applicable</td>
                <td class="arabic">جــهــة الرهــــن</td>
            </tr>
            <tr>
                <td class="arabic">Wadi Al Safa</td>
                <td class="arabic">اسم المنطقة</td>
            </tr>
            <tr>
                <td class="arabic">
                    {{ item.unit_plot_no }}
                </td>
                <td class="arabic">رقم الأرض</td>
            </tr>
        </table>

        <br />

        <p class="name">الموهوب :</p>
        <table>
            <tr>
                <td></td>
                <td class="arabic">الاسم باللغة العربية</td>
            </tr>
            <tr>
                <td v-if="item.buyers.length > 0" class="arabic">
                    <p
                        v-for="buyer in item.buyers"
                        :key="buyer.id"
                        class="arabic"
                    >
                        {{ buyer.buyer_name }}
                    </p>
                </td>
                <td v-if="item.buyers.length == 0" class="arabic">
                    {{ item.buyer_name }}
                </td>
                <td class="arabic">الاسم باللغة الانجليزية</td>
            </tr>
            <tr>
                <td v-if="item.buyers.length > 0" class="arabic">
                    <p
                        v-for="buyer in item.buyers"
                        :key="buyer.id"
                        class="arabic"
                    >
                        {{ buyer.buyer_country_name }}
                    </p>
                </td>
                <td class="arabic" v-if="item.buyers.length == 0">
                    {{ item.buyers[0].buyer_country_name }}
                </td>
                <td class="arabic">الجنسيــــــــــــــــة</td>
            </tr>
            <tr>
                <td v-if="item.buyers.length > 0" class="arabic">
                    <p
                        v-for="buyer in item.buyers"
                        :key="buyer.id"
                        class="arabic"
                    >
                        {{ buyer.buyer_passport }}
                    </p>
                </td>
                <td class="arabic" v-if="item.buyers.length == 0">
                    {{ item.buyers[0].buyer_passport }}
                </td>
                <td class="arabic">رقم الجواز</td>
            </tr>
            <tr>
                <td class="arabic">100%</td>
                <td class="arabic">نسبــة الملكيـــــــة</td>
            </tr>
            <tr>
                <td class="arabic">سكني</td>
                <td class="arabic">طبيعـــة العقــــار</td>
            </tr>
            <tr>
                <td class="arabic">فالكن ستي اوف وندرز</td>
                <td class="arabic">المشــــــــروع</td>
            </tr>
            <tr>
                <td class="arabic">Residential</td>
                <td class="arabic">طبيعـــة العقــــار</td>
            </tr>
            <tr>
                <td class="arabic">Falconcity of Wonders</td>
                <td class="arabic">المشــــــــروع</td>
            </tr>
            <tr>
                <td class="arabic">
                    {{ item.unit_type }}
                </td>
                <td class="arabic">نوع الوحدة</td>
            </tr>
            <tr>
                <td class="arabic">
                    {{ item.unit_no }}
                </td>
                <td class="arabic">رقــم الوحدة</td>
            </tr>
            <tr>
                <td class="arabic">لا يوجد</td>
                <td class="arabic">جــهــة الرهــــن</td>
            </tr>
            <tr>
                <td class="arabic">وادي الصفا 2</td>
                <td class="arabic">اسم المنطقة</td>
            </tr>
            <tr>
                <td class="arabic">AED {{ item.unit_price }}</td>
                <td class="arabic">( قيمـــة البــيـــع (درهم</td>
            </tr>
            <tr>
                <td class="arabic">Not Applicable</td>
                <td class="arabic">جــهــة الرهــــن</td>
            </tr>
            <tr>
                <td class="arabic">Wadi Al Safa</td>
                <td class="arabic">اسم المنطقة</td>
            </tr>
            <tr>
                <td class="arabic">
                    {{ item.unit_plot_no }}
                </td>
                <td class="arabic">رقم الأرض</td>
            </tr>
        </table>
        <br />
        <div class="noc_footer">
            <p>
                لذلك نرجو إصدار سند ملكية العقار المذكور وفقاً لعقد البيع و
                الشراء باسم المشتري أعلاه محملاً بالشرط التالي :
            </p>
            <p>
                يخضع هذا السند لشروط وأحكام نظام المجمع الرئيسي والنظام الأساسي
                لجمعية الملاك فى مشروع فالكن ستي اوف وندرز.
            </p>

            <p>،،، ولكــــم جزيل الشكــــــر والتقـــــــدير</p>
        </div>

        <div>
            <p>
                <strong> عن / فالكن ستي اوف وندرز ذ.م.م </strong>
            </p>
        </div>

        <div class="noc_footer">
            <p style="text-decoration: underline">ملحوظة :</p>

            <p>وافق الواهب على تسليم هذه الشهادة إلى الموهوب له</p>
            <p>دون تحمل شركة فالكن ستي أدنى مسؤولية اتجاهه</p>
            <p>توقيع الواهب :</p>
        </div>

        <div class="row">
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
                <b-button variant="primary" block @click="generateReport"
                    >Download PDF</b-button
                >
            </div>
            <div class="col-lg-4"></div>
        </div>
    </Layout>
</template>

<script>
import { BASE_URL, REQUEST_STATUS } from "../../common";
import axios from "axios";
import VueHtml2pdf from "vue-html2pdf";
import Layout from "../../layouts/main.vue";

export default {
    components: {
        Layout,
        VueHtml2pdf,
    },
    data() {
        return {
            requestType: null,
            workAssigned: [],
            userRole: null,
            requestStatus: REQUEST_STATUS,
            test: "admin",
            show: true,
            item: {},
        };
    },
    mounted() {
        if (localStorage.cs_user_token) {
            this.userRole = localStorage.cs_user_role;
            console.log(this.$route.params.id);
            this.getDetails(this.$route.params.id);
        }
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        getDetails(id) {
            if (localStorage.cs_user_token) {
                axios
                    .get(
                        `${BASE_URL}print/amendments/${id}?token=${localStorage.cs_user_token}`
                    )
                    .then((res) => {
                        console.log(res.data.response);
                        this.show = false;
                        this.item = res.data.response;
                    });
            }
        },
    },
};
</script>
<style scoped>
.section_top {
    text-align: right;
    padding: 20px;
    margin: 5px;
}

td {
    padding: 5px;
    border: 1px solid black;
    color: black;
}
table {
    width: 100%;
    border: 1px solid black;
}
.arabic {
    text-align: right;
}
.name {
    text-align: right;
}

.subject_text {
    text-align: center;
}
.subject_text > h4 {
    text-decoration: underline;
}
.noc_footer {
    text-align: right;
}
p {
    color: black;
}
.pdf {
    margin: 10px;
    padding: 10px;
}
</style>
